import React, { useEffect, useState } from "react";
import axios from "axios";

const AccountStatement = () => {
  const [fees, setFees] = useState({ account_type: "" });
  const [accountData, setAccountData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(null);

  useEffect(() => {
    // Fetch account data from API
    axios
      .get("http://localhost:3001/api/v1/addstudent/accounttype")
      .then((response) => {
        if (response.data.success) {
          setAccountData(response.data.data);
        }
      })
      .catch((error) => console.error("Error fetching account data:", error));
  }, []);

  const handleFeesChange = (event) => {
    const selectedType = event.target.value;
    setFees({ account_type: selectedType });

    // Find matching account type in API data
    const foundAccount = accountData.find((acc) => acc.accountType === selectedType);
    setSelectedAmount(foundAccount ? foundAccount.amount : null);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", textAlign: "center" }}>
      <h2>Account Statement</h2>
      <select
        name="account_type"
        value={fees.account_type}
        onChange={handleFeesChange}
        style={{ width: "100%", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}
      >
        <option value="">Select Account Type</option>
        <option value="ESARA ACC N">ESARA ACC N</option>
        <option value="HIVE ACC TN">HIVE ACC TN</option>
        <option value="AIU ACC U">AIU ACC U</option>
        <option value="ESARA D">ESARA D</option>
        <option value="FEE R">FEE R</option>
        <option value="CASH IN OFFICE">CASH IN OFFICE</option>
        <option value="SWIPE">SWIPE</option>
        <option value="ESARA UZ">ESARA UZ</option>
        <option value="AGENT">AGENT</option>
      </select>

      {selectedAmount !== null && (
        <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
          <h3>Amount: ₹{selectedAmount.toLocaleString()}</h3>
        </div>
      )}
    </div>
  );
};

export default AccountStatement;

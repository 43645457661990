import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Table, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Classes from "./StudentData.module.css";
import axios from "axios";
import Profile from '../Assets/profile.png'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import download from '../Assets/download.png';
import resize from '../Assets/resize.png';
import print from '../Assets/print.png';
import * as XLSX from "xlsx"; // Import xlsx library   
import msalInstance from "../msalConfig";

const StudentData = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");


  // Function to show the modal
  const showModal = (message) => {
    setModalMessage(message);
    setIsModalVisible(true); // Show the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalVisible(false); // Hide the modal
  };

  useEffect(() => {
    const fetchData = async () => {
      // Fetch student data from the API

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
      });
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent`, {
        headers: {
          Authorization: `Bearer ${token.idToken}`
        }
      })
        .then(response => {
          setData(response.data);
          console.log(response.data); // Assuming API response is an array of students
        })
        .catch(error => {
          console.error("Error fetching student data:", error);
        });
    };

    fetchData();
  }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleViewProfile = (studentid) => {
    navigate(`/app/studentprofile/${studentid}`);
    // Navigate to profile page logic here, e.g., navigate(/profile/${id})
  };




  const handleDeleteRow = async (studentId) => {
    if (window.confirm("Are you sure you want to delete this student?")) {
      try {
        // Fetch account details
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);

        // Acquire token silently
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        // Prepare the headers with the acquired token
        const headers = {
          Authorization: `Bearer ${token.idToken}`,
        };

        // DELETE student-related data from multiple APIs sequentially
        try {
          await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentId}`,
            { headers }
          );
          console.log("Student record deleted.");
        } catch (error) {
          console.error("Failed to delete student record:", error.response || error.message);
          setModalMessage("Failed to delete student record. Please try again.");
          setIsModalVisible(true);
          return; // Exit if the student deletion fails
        }

        try {
          // Delete yearly fee data
          await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/yearlyfees/${studentId}`,
            { headers }
          );
          console.log("Yearly fee data deleted.");
        } catch (error) {
          console.error("Failed to delete yearly fee data:", error.response || error.message);
          setModalMessage("Failed to delete yearly fee data. Please try again.");
          setIsModalVisible(true);
          return; // Exit if yearly fee deletion fails
        }

        try {
          // Delete collected fee data
          await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/collectedfee/${studentId}`,
            { headers }
          );
          console.log("Collected fee data deleted.");
        } catch (error) {
          console.error("Failed to delete collected fee data:", error.response || error.message);
          setModalMessage("Failed to delete collected fee data. Please try again.");
          setIsModalVisible(true);
          return; // Exit if collected fee deletion fails
        }

        // If all deletions were successful, update the local state
        const updatedData = data.filter((item) => item.studentid !== studentId);
        setData(updatedData);

        setModalMessage("Student and all related data deleted successfully!");
        setIsModalVisible(true);
      } catch (error) {
        // Catch any unexpected errors
        console.error("Error in the deletion process:", error.response || error.message);
        setModalMessage("An unexpected error occurred while deleting the student. Please try again.");
        setIsModalVisible(true);
      }
    }
  };


  const handleToggleMenu = (id) => {
    setSelectedRow(selectedRow === id ? null : id); // Toggle menu visibility
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter data based on the search query
  const filteredData = data.filter(
    (item) =>
      item.studentid.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.firstname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate paginated data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle search query change and reset pagination
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page whenever the search query changes
  };

  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
    const originalContent = document.body.innerHTML;

    // Replace the document body with the content of printRef
    document.body.innerHTML = printContent.innerHTML;
    window.print();

    // Restore the original document body
    document.body.innerHTML = originalContent;
    window.location.reload(); // Reload to restore event listeners
  };


  // Handles downloading the page as an HTML file
  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData); // Convert data to worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Student Data");
    XLSX.writeFile(workbook, "StudentData.xlsx"); // Save file as StudentData.xlsx
  };

  return (
    <Container
      fluid
      className=" p-4"
      style={{ backgroundColor: "#1E1E2E", minHeight: "210vh" }}
    >
      <nav
        style={{
          "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`, // Custom breadcrumb divider
          fontSize: "1.5rem",
          fontWeight: 500,
          backgroundColor: "#1E1E2E",
        }}
        aria-label="breadcrumb"
        className="text-white d-flex justify-content-between align-items-center"
      >
        <ol className="breadcrumb ">
          <li className="breadcrumb-item text-white">Student Data</li>
          <li className="breadcrumb-item text-white"></li>
        </ol>
        <div className="d-flex align-items-center gap-3">
          {/* Search Bar */}
          <input
            type="text"
            className="form-control custom-placeholder"
            placeholder="Search"
            style={{
              width: "200px",
              backgroundColor: "#2C2C3C",
              border: "1px solid #444",
              color: "#FFFFFF",
            }}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <style>
            {`
              .custom-placeholder::placeholder {
              color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
              }
            `}
          </style>
          {/* Custom Icons */}
          <img
            src={print}
            alt="Print"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Print"
            onClick={handlePrint}
          />
          <img
            src={download}
            alt="Download"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Download"
            onClick={handleDownloadExcel}
          />
          <img
            src={resize}
            alt="Fullscreen"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            title="Fullscreen"
            onClick={() => document.documentElement.requestFullscreen()}
          />
        </div>

      </nav>
      <Row>
        <Col>
          <Card style={{ borderColor: '#3E3E3E' }}>
          <div ref={printRef}>
            <Card.Body className='p-0' style={{ backgroundColor: '#2D2F3A' }}>
                <table className="text-light" style={{ borderCollapse: 'collapse', border: 'none', width: '100%' }}>
                  <thead>
                    <tr>
                      <td className={`text-start `} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "15%", fontWeight: 500, fontSize: '14px' }}>ID Number</td>
                      <td className={`text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "10%", fontWeight: 500, fontSize: '14px' }}>Name</td>
                      <td className={` text-start `} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "10%", fontWeight: 500, fontSize: '14px' }}>Gender</td>
                      <td className={` text-start `} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "5%", fontWeight: 500, fontSize: '14px' }}></td>
                      <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "15%", fontWeight: 500, fontSize: '14px' }}>Passport No.</td>
                      <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "20%", fontWeight: 500, fontSize: '14px' }}>Date of Birth</td>
                      <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "25%", fontWeight: 500, fontSize: '14px' }}>year</td>
                      <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "20%", fontWeight: 500, fontSize: '14px' }}>Nationality</td>
                      <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "5%" }}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((item, index) => (
                      <tr key={index} style={{ borderBottom: "1px solid #3E3E3E" }}>
                        <td className="text-start" style={{ padding: '10px', color: '#EEEEEE' }}>{item.studentid}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#EEEEEE' }}>{item.firstname}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.gender}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.age}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.passportnumber}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.dateofbirth}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.year}</td>
                        <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.nationality}</td>
                        <td className="text-center" style={{ padding: '10px', color: '#A0A0A0', position: 'relative' }}>
                          <Button
                            variant="link"
                            className="p-0"
                            style={{ color: '#A0A0A0', cursor: 'pointer', textDecoration: 'none' }}
                            onClick={() => handleToggleMenu(item.studentid)}
                          >
                            ...
                          </Button>
                          {selectedRow === item.studentid && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '100%',
                                left: -150,
                                backgroundColor: '#1E1E2E',
                                borderRadius: '8px',
                                padding: '2px',
                                zIndex: 1,
                                width: '150px',
                                display: 'table'
                              }}
                            >
                              <div style={{ display: 'table-row', borderBottom: '1px solid #3E3E3E' }}>
                                <div
                                  onClick={() => handleViewProfile(item.studentid)}
                                  style={{ color: '#EEEEEE', padding: '5px 10px', cursor: 'pointer', }}
                                >
                                  <img src={Profile} alt="profile-img" style={{ marginRight: '8px', width: '30px', height: '30px' }}></img>View Profile
                                </div>
                              </div>
                              <div style={{ display: 'table-row' }}>
                                <div
                                  onClick={() => {
                                    handleDeleteRow(item.studentid);
                                    showModal();
                                  }}
                                  style={{ display: 'flex', justifyContent: 'center', color: '#EEEEEE', padding: '5px 10px', cursor: 'pointer', alignItems: 'start' }}
                                >
                                  <i className="bi bi-trash" style={{ marginRight: '8px', color: '#FF0000' }}></i>Delete
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </Card.Body>
            </div>
          </Card>
          {/* Modal with blur effect */}
          {isModalVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(8px)", // Apply blur effect
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: "#1E1E2E",
                  padding: "20px 30px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  width: "90%",
                  maxWidth: "400px",
                  zIndex: 1001,
                }}
              >
                <p
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#EEE",
                  }}
                >
                  {modalMessage}
                </p>
                <button
                  onClick={closeModal}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    padding: "8px 16px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-2">
            <Button
              variant="secondary"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <div className="d-flex justify-content-center mt-3">
              {[...Array(totalPages)].map((_, index) => (
                <Button
                  key={index}
                  variant={currentPage === index + 1 ? '#000' : 'outline-primary'}
                  onClick={() => handlePageChange(index + 1)}
                  className="mx-1" // You can adjust this to "mx-0" for no horizontal margin
                  style={{
                    color: currentPage === index + 1 ? "#ffffff" : "#757575", // text color
                    backgroundColor: currentPage === index + 1 ? "#3A3F51" : "transparent", // background color
                    border: currentPage === index + 1 ? "none" : "1px solid #757575" // border color
                  }}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
            <Button
              variant="secondary"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentData;

import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Container, Row, Col, Table } from 'react-bootstrap';
import eSara from '../Assets/esara1.png'
import Logo from '../Assets/logo.png'
import msalInstance from "../msalConfig";
import axios from 'axios';
import { useRef } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";



function EsaraReceipt() {
    const { studentid, receiptid } = useParams(); // Use useParams to get the route parameter
    const [studentData, setStudentData] = useState({});
    const [collectedFee, setCollectedFee] = useState('');
    const [transactionid, setTransactionid] = useState('');
    const [accountType, setAccountType] = useState('');
    const [dateoffeecollection, setDateoffeecollection] = useState('');
    const [pendingFee, setPendingFee] = useState('');
    const [yearfee, setYearfee] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                    throw new Error("No accounts found");
                }
                msalInstance.setActiveAccount(accounts[0]);
                const token = await msalInstance.acquireTokenSilent({
                    scopes: ["user.read"]
                });
                // Using Promise.all to fetch all the data concurrently
                const [studentResponse, collectedFeeResponse, pendingFeeResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentid}`, {
                        headers: {
                            Authorization: `Bearer ${token.idToken}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/collectedfee`,
                        {
                            headers: {
                                Authorization: `Bearer ${token.idToken}`
                            }
                        }
                    ),
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/calculatePendingFee?student_id=${studentid}`, {
                        headers: {
                            Authorization: `Bearer ${token.idToken}`
                        }
                    })
                ]);

                // Filter the data by receipt_id
                const filteredData = collectedFeeResponse.data.find((item) => item.receipt_id === receiptid);

                // Set the collected fee if found
                setCollectedFee(filteredData ? filteredData.fee_collected : "Not Found");
                setTransactionid(filteredData ? filteredData.transaction_id : "Not Found");
                setAccountType(filteredData ? filteredData.accound_type : "Not Found");
                // Set the state with fetched data
                setStudentData(studentResponse.data);


                setDateoffeecollection(filteredData ? filteredData.date_of_fee_collection : "Not Found");

                setPendingFee(pendingFeeResponse.data.pending_fee || ''); // Ensure correct field path

                console.log("Student Data:", studentResponse.data);
                console.log("Collected Fee Data:", collectedFeeResponse.data);

                console.log("Pending Fee Response:", pendingFeeResponse);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, [studentid, receiptid]);

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                // Fetch the token
                const token = await msalInstance.acquireTokenSilent({
                    scopes: ["user.read"]
                });

                // Make the API request with the token
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/yearlyfee/${studentid}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token.idToken}`,
                        }
                    }
                );

                // Update state with the fetched data
                setYearfee(response.data);
            } catch (error) {
                console.error("Error fetching student data:", error);
            }
        };

        fetchStudentData();
    }, [studentid]);

    function printContainer() {
        // Ensure the element exists
        var content = document.getElementById("printedpage");

        if (content) {
            console.log("Found printedpage element");

            // Clone the content to preserve styles
            var clone = content.cloneNode(true);

            // Copy inline styles of each element recursively
            function copyStyles(sourceElement, clonedElement) {
                const computedStyles = window.getComputedStyle(sourceElement);
                for (let property of computedStyles) {
                    clonedElement.style[property] = computedStyles.getPropertyValue(property);
                }
                Array.from(sourceElement.children).forEach((child, index) => {
                    copyStyles(child, clonedElement.children[index]);
                });
            }

            copyStyles(content, clone);

            // Create the print window
            var printWindow = window.open('', '', 'height=600,width=800');
            console.log("Print window opened");

            // Add Bootstrap stylesheet to the print window and custom styles for printing
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print</title>
                        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-Mc5oqod23fJzlwfYUVB0KR5cvO7uEioyS7t27i/j4LLcyMFEnMRbK5a4Qz0QUJjZ" crossorigin="anonymous">
                        <style>
                            /* Custom styles for printing */
                            body {
                                margin: 0;
                                padding: 0;
                                overflow: hidden ; /* Hide scrollbars */
                            }
                            #printedpage {
                                width: 100%;
                                height: 200px;
                                overflow: hidden; /* Hide overflow inside printed content */
                                page-break-before: always;
                            }
                            @media print {
                                body {
                                    overflow: hidden !important;
                                    margin: 0;
                                    padding: 0;
                                }
                                #printedpage {
                                   width: 100%;
        height: auto;
        overflow: hidden;
        background: linear-gradient(to right, #002666 0%, #2E5ACB 100%) !important; /* Ensure gradient applies */
        -webkit-print-color-adjust: exact; /* Ensure color accuracy */
        print-color-adjust: exact;
                                }
                                .container {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                            /* Optional: Additional styles for print */
                            @page {
                                margin: 0;
                            }
                        </style>
                    </head>
                    <body>
                        ${clone.outerHTML}
                    </body>
                </html>
            `);

            printWindow.document.close();
            printWindow.print();
            console.log("Printed");
        } else {
            console.error('Element with id "printedpage" not found');
        }
    }


    return (
        <div className="container-fluid min-vh-100 d-flex align-items-start justify-content-center"


            style={{ backgroundColor: '#1E1E2E' }}  >

            <div className="row w-100">
                <div className="col p-0">
                    <nav
                        style={{
                            // "--bs-breadcrumb-divider": url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E"),
                            fontSize: '1.5rem',
                            fontWeight: 500,
                            backgroundColor: '#1E1E2E'
                        }}


                        aria-label="breadcrumb m-3"
                        className="p-3 text-white d-flex justify-content-between align-items-center"
                    >
                        <ol className="breadcrumb m-2">
                            <li className="breadcrumb-item text-white">
                                Student Admit
                            </li>
                            <li className="breadcrumb-item active" aria-current="page"></li>
                        </ol>

                    </nav>
                    <div className='mx-auto col-md-12 mb-5 d-flex row justify-content-center' style={{
                        backgroundColor: '#2D2F3A',
                        borderRadius: '8px',
                        padding: '25px',
                    }}>
                        <div
                            className="container w-100 p-10" id="printedpage"
                            style={{ backgroundColor: '#FFFFFF' }}
                        >
                            <div className='container w-100' style={{ background: 'linear-gradient(to right, #002666 0%, #2E5ACB 100%)' }}>
                                <div className="row align-items-center justify-content-between flex-nowrap">
                                    <div className="col-3 col-md-2 d-flex justify-content-start">
                                        <img src={eSara} alt="eSaraLogo" style={{ maxWidth: '100%' }} />
                                    </div>
                                    <div className="col-3 col-md-2 d-flex justify-content-end">
                                        <img src={eSara} alt="eSaraLogo" style={{ maxWidth: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='container w-100 m-0'>
                                <div className='row align-items-center mt-1'>
                                    {/* Left side: University name */}
                                    <div className="col-6 d-flex justify-content-start">
                                        <h2 style={{ fontSize: 'clamp(18px, 3vw, 1.2rem)' }}>
                                            ASIA INTERNATIONAL UNIVERSITY MEDICAL FACULTY
                                        </h2>
                                    </div>

                                    {/* Right side: Logo + Year + Ledger Account */}
                                    <div className="col-6 d-flex justify-content-end align-items-center">
                                        {/* Ledger Account Section */}
                                        <div className="col-4 col-md-4 d-flex flex-column align-items-end text-black">
                                            <div className='d-flex flex-column align-items-center'>
                                                <h5 className='mb-1' style={{ fontSize: 'clamp(12px, 2.5vw, 1rem)' }}>Ledger Account</h5>
                                                <h6 className='mb-1' style={{ fontSize: 'clamp(12px, 2vw, 0.9rem)' }}>2024-25</h6>
                                                <h6 className='mb-1' style={{ fontSize: 'clamp(12px, 2vw, 0.9rem)' }}>Payment Due</h6>
                                                <h6 className='mb-1' style={{ color: '#FF4848', fontSize: 'clamp(12px, 2vw, 0.9rem)' }}>{pendingFee}</h6>
                                            </div>
                                        </div>
                                        {/* Move Logo & Year to the left of Ledger Account */}
                                        <div className="d-flex align-items-center me-3">
                                            <img src={Logo} alt="eSaraLogo" style={{ maxWidth: '100%', height: 'auto' }} />
                                            <h6 className="mb-0 ms-2" style={{ color: '#D80303', fontSize: 'clamp(12px, 1.8vw, 16px)' }}>
                                                2024-25
                                            </h6>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <Container className="p-3" style={{ backgroundColor: '#FFFFFF' }}>

                                <Table bordered responsive className='mb-1' style={{ tableLayout: 'fixed', width: '100%', borderColor: '#333333' }}>
                                    <thead>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Class Name</td>
                                            <td><input type="text" name="class_name" value={studentData.classname} style={{ width: "100%", border: "none" }} /></td>
                                            <td style={{ fontWeight: "bold" }}>Name</td>
                                            <td colSpan={5}><input type="text" name="firstname" value={studentData.firstname} onChange={""} style={{ width: "100%", border: "none", textTransform: "uppercase" }} /></td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Group</td>
                                            <td><input type="text" name="group_name" value={studentData.group} onChange={""} style={{ width: "100%", border: "none" }} /> </td>
                                            <td style={{ fontWeight: "bold" }}>Sur Name</td>
                                            <td colSpan={5}> <input type="text" name="fathersName" value={studentData.lastname} onChange={""} style={{ width: "100%", border: "none", textTransform: "uppercase" }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Admission</td>
                                            <td><input type="text" name="admissionno" value={studentData.admissionno} onChange={""} style={{ width: "100%", border: "none" }} /></td>
                                            <td style={{ fontWeight: "bold" }}>Student ID</td>
                                            <td><input type="text" name="studentid" value={studentData.studentid} onChange={""} style={{ width: "100%", border: "none" }} /></td>
                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>DOB</td>
                                            <td><input type="text" name="dateofbirth" value={studentData.dateofbirth} onChange={""} style={{ width: "100%", border: "none" }} /></td>
                                            <td style={{ textAlign: "center", fontWeight: "bold" }}>P.No</td>
                                            <td><input type="text" name="localphone" value={studentData.localphone} onChange={""} style={{ width: "100%", border: "none" }} /></td>
                                        </tr>
                                    </thead>
                                </Table>
                                <div className="d-flex justify-content-end mb-1">
                                    <Table className="table table-bordered" style={{ tableLayout: 'auto', width: '35%', borderColor: '#333333' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '28%', color: '#D80303', textAlign: 'center', fontWeight: 'bold' }}>Arrears</td>
                                                <td><input type="text" name="arrears" value={""} onChange={""} style={{ width: "100%", border: 'none' }} /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div><Table bordered responsive className='mb-1' style={{ borderColor: '#333333' }}>
                                    <thead>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Year</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>1st Sem</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Visa</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>2nd Sem</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Medical</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Hostel</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Mess</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>L/Main</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>TransP</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Fine</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Disc</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Total</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input name="year"
                                                    value={studentData.year}
                                                />
                                            </td>
                                            <td><input type="text" inputMode="numeric" value={yearfee.total_fee} onChange={""} name="firstsem" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={yearfee.visa_fee} onChange={""} name="vise" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={""} onChange={""} name="secondsem" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={""} onChange={""} name="medical" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={yearfee.hostel_fee} onChange={""} name="hostel" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={yearfee.mess_fee} onChange={""} name="mess" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={""} onChange={""} name="L_main" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={""} onChange={""} name="transp" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={""} onChange={""} name="fines" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" inputMode="numeric" value={""} onChange={""} name="discount" style={{ width: "100%", border: "none" }} /></td>
                                            <td><input type="text" value={yearfee.total_fee} name="total" readOnly style={{ width: "100%", border: "none", backgroundColor: "#f0f0f0", }} /></td>
                                        </tr>
                                    </tbody>
                                </Table>




                                <Table className="table table-bordered mb-1" style={{ tableLayout: 'auto', width: '100%', borderColor: '#333333' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '28%', fontWeight: "bold" }}>Total Slip Amount</td>
                                            <td>  <input
                                                type="text"
                                                value={pendingFee}
                                                readOnly
                                                style={{ width: "100%", border: "none", backgroundColor: "#f0f0f0" }}
                                            /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                {/* Total Slip Amount Section */}
                                <Table bordered responsive className='mb-1' style={{ borderColor: '#333333' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Transaction Number</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Transaction Date</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Account Type</td>
                                            <td style={{ textAlign: 'center', fontWeight: "bold" }}>Amount Received</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="transaction_id"
                                                    value={transactionid}
                                                    onChange={""}
                                                    style={{ width: "100%", border: 'none' }}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="date_of_fee_collection"
                                                    value={dateoffeecollection} // use fees.transaction_date
                                                    onChange={""}
                                                    style={{ width: "100%", border: 'none' }}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    name="accound_type"
                                                    value={accountType} // use fees.account_type
                                                    onChange={""}

                                                    style={{ width: "100%", border: 'none' }}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={collectedFee} // use fees.fee_collected
                                                    onChange={""}
                                                    name='fee_collected'
                                                    style={{ width: "100%", border: 'none' }}
                                                />
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                                <Table className="table table-bordered mb-1" style={{ tableLayout: 'auto', width: '100%', borderColor: '#333333' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '28%', fontWeight: "bold" }}>Total Receive Amount</td>
                                            <td><input
                                                type="text"
                                                value={collectedFee} // use fees.fee_collected
                                                onChange={""}
                                                name='fee_collected'
                                                style={{ width: "100%", border: 'none' }}
                                            /></td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <div className="d-flex justify-content-between mb-1">
                                    <div style={{ width: '50%' }}>
                                        <div>
                                            <Table bordered style={{ width: '100%', borderColor: '#333333' }} className='mb-1'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '40%', fontWeight: "bold" }}>Total Slip Amount</td>
                                                        <td>  <input
                                                            type="text"
                                                            value={pendingFee}
                                                            readOnly
                                                            style={{ width: "100%", border: "none", backgroundColor: "#f0f0f0" }}
                                                        /></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div>
                                            <Table bordered style={{ width: '100%', borderColor: '#333333' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '40%', fontWeight: "bold" }}>Total Received Amount</td>
                                                        <td><input
                                                            type="text"
                                                            value={collectedFee} // use fees.fee_collected
                                                            onChange={""}
                                                            name='fee_collected'
                                                            style={{ width: "100%", border: 'none' }}
                                                        /></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className='mt-4' style={{ width: '40%' }}>
                                        <Table bordered style={{ width: '100%', borderColor: '#333333' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: '40%', color: '#FF0000', fontWeight: 'bold', textAlign: 'center' }}>Current Balance</td>
                                                    <td><input type="number" name="currentbalance" value={pendingFee} style={{ width: '100%', textAlign: 'center', border: 'none' }} /> </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Container>
                            <Container fluid className="p-4">
                                {/* Footer Disclaimer */}
                                <Row>
                                    <Col>
                                        <div style={{
                                            background: 'linear-gradient(to right, #002666 0%, #2E5ACB 100%)',
                                            color: '#ffffff',
                                            padding: '10px',
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}>
                                            The above mentioned amount once paid are not-refundable under any circumstances.
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div
                            className="d-flex justify-content-center gap-3"
                            style={{ padding: '20px 0' }}
                        >
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={printContainer} // Function reference is correctly passed
                                style={{
                                    color: '#EEEEEE', // Submit button font color 
                                    borderColor: 'rgba(41, 112, 255, 1)', // Submit button border color
                                }}
                            >
                                Print
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default EsaraReceipt;
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "a676ae91-0df0-43fc-81dc-9113687bb6bf", // Replace with your Application (client) ID
        authority: "https://login.microsoftonline.com/f989a61c-1b91-4b0b-b964-fbe3014dad92", // Replace with your Directory (tenant) ID
        redirectUri: "https://esarastdmgt.ethicsecur.co.in", // Redirect URI registered in Azure AD
    },
    cache: {
        cacheLocation: "localStorage", // or "sessionStorage"    //Its used for global use https://esarastdmgt.ethicsecur.co.in
        storeAuthStateInCookie: false, // Set to true for IE11/Edge compatibility   //Its used for local use http://localhost:3000
    }, 
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;

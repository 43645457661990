import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const LedgerAccount = () => {
    const [incomeData, setIncomeData] = useState([]);
    const [expenseData, setExpenseData] = useState([]);
    const tableRef = useRef(); // Reference for the table
    const [fees, setFees] = useState({ account_type: "" });
    const [accountData, setAccountData] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        return today.toISOString().slice(0, 10);
    });
    const [categoryFilter, setCategoryFilter] = useState(""); // To track the selected category filter

    useEffect(() => {
        const fetchData = async () => {
            try {
                const incomeResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/adminpanel/incomeget`
                );
                setIncomeData(incomeResponse.data || []);

                const expenseResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/adminpanel/expenceget`
                );
                setExpenseData(expenseResponse.data || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const expenseCategories = {
        Paid_to_university_fee_by_esara: "Paid to University Fee by Esara",
        Hostel_expenses: "Hostel Expenses",
        Mess_expenses: "Mess Expenses",
        Transportation: "Transportation",
        Miscellaneous_esara_uni_expenses: "Miscellaneous Esara Uni Expenses",
        Miscellaneous_uzbekistan_esara_expense: "Miscellaneous Uzbekistan Esara Expenses",
        Miscellaneous_esara_india_expense: "Miscellaneous Esara India Expenses",
        Miscellaneous_esara_nepal_expense: "Miscellaneous Esara Nepal Expenses",
        Undefined_expense_in_uzbekistan: "Undefined Expense in Uzbekistan",
        Other_expenses: "Other Expenses",
    };

    const getExpenseCategories = (item) => {
        return Object.entries(item)
            .filter(([key, value]) => value > 0 && expenseCategories[key])
            .map(([key]) => expenseCategories[key])
            .join(", ");
    };

    const filteredIncomeData = incomeData.filter((item) =>
        selectedDate
            ? new Date(item.created_at).toISOString().slice(0, 10) === selectedDate
            : true
    );

    const filteredExpenseData = expenseData.filter((item) => {
        const matchesDate = selectedDate
            ? new Date(item.created_at).toISOString().slice(0, 10) === selectedDate
            : true;

        const matchesCategory = categoryFilter
            ? Object.entries(item).some(
                ([key, value]) => key === categoryFilter && value > 0
            )
            : true;

        return matchesDate && matchesCategory;
    });

    useEffect(() => {
        // Fetch account data from API
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/accounttype`)
            .then((response) => {
                if (response.data.success) {
                    setAccountData(response.data.data);
                }
            })
            .catch((error) => console.error("Error fetching account data:", error));
    }, []);

    const handleFeesChange = (event) => {
        const selectedType = event.target.value;
        setFees({ account_type: selectedType });

        // Find matching account type in API data
        const foundAccount = accountData.find((acc) => acc.accountType === selectedType);
        setSelectedAmount(foundAccount ? foundAccount.amount : null);
    };

    const handlePrint = () => {
        const printContents = tableRef.current.innerHTML; // Get table HTML
        const originalContents = document.body.innerHTML; // Save the original HTML

        document.body.innerHTML = printContents; // Replace body with the table
        window.print(); // Trigger the print dialog
        document.body.innerHTML = originalContents; // Restore the original HTML
    };

    return (
        <div className="p-4">
            <div style={{ textAlign: "right" }}>
                <button
                    style={{
                        marginBottom: "20px",
                        padding: "10px 20px",
                        backgroundColor: "#3A3F51",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                    }}
                    onClick={handlePrint}
                >
                    Print
                </button>
            </div>
            <div ref={tableRef}>
                <style>
                    {`
                        @media print{
                            select{
                                display:none;
                             }
                        }
                    `}
                </style>
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginBottom: "20px",
                    }}
                >
                    <thead>
                        <tr>
                            <th style={cellStyle} colSpan={11}>
                                <div style={{ margin: "10px auto" }}>
                                    <h2 style={{ textAlign: "center" }}>LEDGER ACCOUNT</h2>
                                    <h3 style={{ textAlign: "center" }}>
                                        INCOME AND EXPENSES DETAILS
                                    </h3>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style={cellStyle} colSpan={11}>
                                <div style={{ textAlign: "right" }}>
                                    <label>Date:</label>
                                    <input
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)} // Update selected date
                                    />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style={cellStyle} colSpan={5}>
                                INCOME DATA
                            </th>
                            <th style={cellStyle} colSpan={5}>
                                EXPENSES DATA
                            </th>
                            <th style={cellStyle}>Account Statements</th>
                        </tr>
                        <tr>
                            <th style={cellStyle}>SI NO</th>
                            <th style={cellStyle}>PARTICULARS</th>
                            <th style={cellStyle}>USD</th>
                            <th style={cellStyle}>CATEGORY</th>
                            <th style={cellStyle}>REMARKS</th>
                            <th style={cellStyle}>SI NO</th>
                            <th style={cellStyle}>PARTICULARS</th>
                            <th style={cellStyle}>CATEGORY
                                <select
                                    value={categoryFilter}
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                >
                                    <option value="">All Categories</option>
                                    {Object.entries(expenseCategories).map(([key, value]) => (
                                        <option key={key} value={key}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                            </th>
                            <th style={cellStyle}>USD</th>
                            <th style={cellStyle}>REMARKS</th>
                            <th style={cellStyle}><select
                                name="account_type"
                                value={fees.account_type}
                                onChange={handleFeesChange}
                                style={{ width: "100%", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}
                            >
                                <option value="">Select Account Type</option>
                                <option value="ESARA ACC N">ESARA ACC N</option>
                                <option value="HIVE ACC TN">HIVE ACC TN</option>
                                <option value="AIU ACC U">AIU ACC U</option>
                                <option value="ESARA D">ESARA D</option>
                                <option value="FEE R">FEE R</option>
                                <option value="CASH IN OFFICE">CASH IN OFFICE</option>
                                <option value="SWIPE">SWIPE</option>
                                <option value="ESARA UZ">ESARA UZ</option>
                                <option value="AGENT">AGENT</option>
                            </select></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredIncomeData.map((income, index) => (
                            <tr key={index}>
                                <td style={cellStyle}>{index + 1}</td>
                                <td style={cellStyle}>
                                    {new Date(income.created_at).toLocaleString()}
                                </td>
                                <td style={cellStyle}>{income.Total_income || 0}</td>
                                <td style={cellStyle}>Income Category</td>
                                <td style={cellStyle}>
                                    <input type="text" style={{ width: "100%", border: "none" }} />
                                </td>
                                <td style={cellStyle}>
                                    {filteredExpenseData[index] ? index + 1 : ""}
                                </td>
                                <td style={cellStyle}>
                                    {filteredExpenseData[index]
                                        ? new Date(filteredExpenseData[index].created_at).toLocaleString()
                                        : "Nil"}
                                </td>
                                <td style={cellStyle}>
                                    {filteredExpenseData[index]
                                        ? getExpenseCategories(filteredExpenseData[index])
                                        : "Nil"}
                                </td>
                                <td style={cellStyle}>
                                    {filteredExpenseData[index]
                                        ? filteredExpenseData[index].Total_expense || 0
                                        : "Nil"}
                                </td>
                                <td style={cellStyle}>
                                    <input type="text" style={{ width: "100%", border: "none" }} />
                                </td>
                            </tr>
                        ))}

                        <tr>
                            <td colSpan="2" style={{ ...cellStyle, textAlign: "right", fontWeight: "bold" }}>
                                Total
                            </td>
                            <td style={cellStyle}>
                                {filteredIncomeData.reduce(
                                    (total, income) => total + (income.Total_income || 0),
                                    0
                                )}
                            </td>
                            <td style={cellStyle}></td>
                            <td style={cellStyle}></td>
                            <td colSpan="3" style={{ ...cellStyle, textAlign: "right", fontWeight: "bold" }}>
                                Total
                            </td>
                            <td style={cellStyle}>
                                {filteredExpenseData.reduce(
                                    (total, expense) => total + (expense.Total_expense || 0),
                                    0
                                )}
                            </td>
                            <td style={cellStyle}></td>
                            <td style={{ ...cellStyle, border: '1px solid #000' }}>
                                {selectedAmount !== null ? selectedAmount.toLocaleString() : ''}
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div style={{ textAlign: "START", fontWeight: "bold", marginTop: "20px" }}>
                    MAIN INCOME AND EXPENSES
                </div>
            </div>
        </div>
    );
};

const cellStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
};

export default LedgerAccount;
